import React from 'react';
import Jobs from './Jobs';
import Nav from '../components/Nav';
import Plus from '../components/Plus';
import '../styles/App.css';
import downArrow from '../assets/downArrow.svg';
import arrowSide from '../assets/arrowSide.svg';

function App() {
  return (
    <>
      <div className="container">
        <Nav/>
        <div className="body">
          <div className="plusElement">
            <Plus/>
          </div>
          <div className="rightElements">
            <div className="us">
            <div id="contact">
                <h2>CONTACT</h2>
                <div className="arrowLinkContainer">
                  <a className="arrowLink inlineContent" href="mailto:igor@atomicsear.ch"><p>Email</p>&nbsp;&nbsp;<img className="arrowLinkImg inlineContent" src={arrowSide}></img></a>
                </div>
                <br/>
                <div className="arrowLinkContainer">
                  <a className="arrowLink inlineContent" href="https://twitter.com/atomicsearchinc"><p>Twitter</p>&nbsp;&nbsp;<img className="arrowLinkImg inlineContent" src={arrowSide}></img></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="space"></div>
      <div className="bottomBar"></div>
    </>
  );
}

export default App;
