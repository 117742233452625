import React from 'react';
import Nav from '../components/Nav';
import '../styles/App.css';
import backArrow from '../assets/arrowBack.svg';
import downArrow from '../assets/downArrow.svg';

function App() {
  return (
    <>
      <div className="container">
        <Nav/>
        <a className="jobsBackBtn" href="/"><img src={backArrow}></img>&nbsp;Back</a>
        <div className="jobsDesc">
            <h1 id="jobsTitle">Founding Engineer</h1>
            <h2 className="jobsSubTitles">WHO WE ARE</h2>
            <p className="arrowTxt txt">Atomic transforms unstructured text into searchable, self-evolving knowledge graphs. Our mission is to usher in the next paradigm of general purpose search - to realize the dream of the Semantic Web.</p>
            
            <h2 className="jobsSubTitles">WHO WE NEED</h2>

            <div className="arrowPoint">
              <div className="arrowImg">
                <img className="downArrow" src={downArrow} alt="down arrow"></img>
              </div>
              <div className="arrowAndTxt">
                <p className="arrowTxt">Atomic Search is looking for an engineer to join our founding team - someone who:</p>
                <p className="arrowTxt indent">+ Is driven by our mission to significantly enhance human thinking and education</p>
                <br></br>
                <p className="arrowTxt indent">+ Is excited about operating in uncharted territory, working daily with cutting edge technology and implementations. This role will involve constant learning, iteration and revision.</p>
                <p className="arrowTxt indent">+ Is energized by the idea of developing a truly innovative product in a completely greenfield space.</p>
                <p className="arrowTxt indent">+ Wants to be a part of a small, fast moving, mission-driven team where they have ownership in the long term success of the company.</p>
              </div>
            </div>
            <hr className="arrowPointHr"/>

            <h2 className="jobsSubTitles">SKILLS</h2>

            <div className="arrowPoint">
              <div className="arrowImg">
                <img className="downArrow" src={downArrow} alt="down arrow"></img>
              </div>
              <div className="arrowAndTxt">
                <p className="arrowTxt">Computer Science:</p> <br></br>
                <p className="arrowTxt indent">+ Fluency in Python for Data Science</p> <br></br>
                <p className="arrowTxt indent">+ Familiarity with limitations of NLP</p> <br></br>
                <p className="arrowTxt indent">+ Familiarity with NLU techniques</p> <br></br>
                <p className="arrowTxt indent">+ Familiarity with knowledge graphs</p>
                <p className="arrowTxt indent">+ Familiarity with Semantic Web concepts</p>
              </div>
            </div>
            <hr className="arrowPointHr"/>

            <div className="arrowPoint">
              <div className="arrowImg">
                <img className="downArrow" src={downArrow} alt="down arrow"></img>
              </div>
              <div className="arrowAndTxt">
                 <p className="arrowTxt">Mathematics:</p> <br></br>
                <p className="arrowTxt indent">+ Excellent working knowledge of applied Discrete Mathematics</p> <br></br>
                <p className="arrowTxt indent">+ Strong background in formal logic</p> <br></br>
              </div>
            </div>
            <hr className="arrowPointHr"/>

            <h2 className="jobsSubTitles">WHAT YOU'LL BE DOING</h2>

            <div className="arrowPoint">
              <div className="arrowImg">
                <img className="downArrow" src={downArrow} alt="down arrow"></img>
              </div>
              <div className="arrowAndTxt">
                <p className="arrowTxt">This team member will help architect and implement an end-to-end unstructured text to knowledge graph solution. </p>
              </div>
            </div>
            <hr className="arrowPointHr"/>
            
            <div className="arrowPoint">
              <div className="arrowImg">
                <img className="downArrow" src={downArrow} alt="down arrow"></img>
              </div>
              <div className="arrowAndTxt">
                <p className="arrowTxt">They will add contextual density to our knowledge graph by researching and implementing a custom reasoning engine.</p>
              </div>
            </div>
            <hr className="arrowPointHr"/>

            <div className="arrowPoint">
              <div className="arrowImg">
                <img className="downArrow" src={downArrow} alt="down arrow"></img>
              </div>
              <div className="arrowAndTxt">
                <p className="arrowTxt">They will be going beyond NLP to construct a first of its kind NLU system.</p>
              </div>
            </div>
            <hr className="arrowPointHr"/>

            <h2 className="jobsSubTitles">INTERESTED?</h2>
            <p className="arrowTxt txt"><a href="mailto:igor@atomicsear.ch">Contact us</a> with:</p>
            <br></br>
            <p className="arrowTxt indent">+ Resume</p>
            <br></br>
            <p className="arrowTxt indent">+ What excites you about Atomic?</p>
            <br></br>
            <p className="arrowTxt indent">+ Why do you think you are the right person for this role?</p>
            <br></br>
            <p className="arrowTxt indent">+ What is the most exceptional thing you have accomplished?</p>
        </div>
      </div>
      <div className="bottomBar"></div>
    </>
  );
}

export default App;
